import React from "react"

const Footer = () => {
  return (
    <footer className="my-12 text-center">
      <p>{new Date().getFullYear()} © just.sh</p>
    </footer>
  )
}

export default Footer
